import type { Menu, ScalpCare, TreatmentMenu } from './menu'
import type { SimpleStaff } from './staff'
import type {Reservation} from './reservation'

export { State, ReservedBy } from './reservation'

export type {
  OperationHistory,
  Reservation,
  UsedDyestuff1,
  UsedDyestuff2,
} from './reservation'
export type { SimpleStaff } from './staff'
export type { Menu, MenuOption, ScalpCare, TreatmentMenu } from './menu'
export type { Customer } from './customer'

export interface ShiftBreak {
  type: 'break' | 'block'
  text: string
  start: string
  end: string
  readonly startTime: string
  readonly endTime: string
}

export interface Shift {
  readonly id: string
  shop: string
  staff: string
  readonly staffName: string
  date: string
  start: string
  end: string
  breaks: ShiftBreak[]
  readonly isProbation: boolean
  readonly isDummy: boolean

  isVacation: boolean
  isPaidVacation: boolean
}

export interface TimeCard {
  readonly id: string
  shop: string
  date: string
  staff: string
  startTime: string
  endTime: string
}

export interface ReserveFrame {
  readonly start: string
  readonly minutes: number
  readonly staffId: string
  readonly staffName: string
}

export interface ReserveAailability {
  readonly at: string
  readonly time: string
  readonly sinkCount: number
  readonly staffCount: number
  // staff idの配列
  readonly shortStaffs: string[]
  readonly longStaffs: string[]
  readonly availableShort: boolean
  readonly availableLong: boolean

  readonly staffs: string[]
  readonly available: boolean
}

export interface WrappedReserve {
  readonly start: string
  readonly segmentMinutes: number[]
  staffId: string | null
  staffName: string | null
  shampooStaffId: string | null
  shampooStaffName: string | null

  readonly reservation: Reservation
}

export interface Dyestuff {
  readonly id: number
  isActive: boolean
  name: string
  brand: string
  // shop: string
}
export type ForceReservableTime = {
  readonly id: number
  startAt: string
  shop: string
}

export type Overflow = {
  at: string
  time: string
  overflowCount: number
  staffCount: number
  reserveCount: number
}

export interface Timeline {
  readonly fufumore: boolean
  readonly date: string
  readonly start: string
  readonly end: string
  readonly shop: {
    readonly id: string
    readonly name: string
  }
  readonly unitMinutes: number
  readonly menus: Menu[]
  //readonly menuOptions: MenuOption[]
  readonly treatments: TreatmentMenu[]
  readonly shifts: Shift[]
  readonly frames: ReserveFrame[]
  readonly reservationFrames: WrappedReserve[]

  // Shiftにはいないが割当可能なstaff. 予約対応などで利用
  readonly staffs: SimpleStaff[]
  readonly dyestuffs: Dyestuff[]

  readonly availabilities: ReserveAailability[]
  readonly timeCards: TimeCard[]
  readonly scalpCares: ScalpCare[]

  readonly forceReservables: ForceReservableTime[]
  readonly overflows: Overflow[]

  readonly confirmationRequired?: Reservation[]
}

export interface CancelRate {
  readonly at: string
  readonly rate: number
}

export interface CancelPrice {
  readonly rates: CancelPrice[]
  readonly paid: number
  readonly cancel: number | null
  readonly changeDateTime: number | null
}

export type Information = {
  id: string
  shop: string
  readonly shopName: string
  readonly shopIsGroup: Blob
  publishAt: string
  message: string
  isHeadline: boolean
}

export type Subscription = {
  readonly id: number
  readonly planName: string
  readonly nextPlanName: string
  readonly startAt: string
  readonly expiringAt: string

  plan: number
  nextPlan: number
  readonly user: string
  readonly charge: number
  readonly next: null | number
}

export type Goods = {
  readonly id: number
  isActive: boolean
  position: number
  name: string
  shortName: string
  price: string
}

export type PurchaseHistory = {
  readonly id: number
  date: string
  customer: string
  goods: number
  readonly goodsShortName: string
  quantity: number
  shop: string
  created: string
}

export type GoodsPurchase = {
  id: number
  readonly goodsName: string
  readonly unitPrice: number
  readonly shopCode: string
  readonly shopName: string
  readonly customerCode: string
  readonly customerName: string

  date: string
  quantity: number
  customer: string
  goods: number
  shop: string
  created: string
}



export type Holiday = {
  readonly id: number
  readonly shopCode: string
  readonly shopName: string
  shop: string
  date: string
  memo: string
  createdAt: string
  updatedAt: string
}

export type OpenDay = {
  readonly id: number
  readonly shopCode: string
  readonly shopName: string
  shop: string
  date: string
  openTime: string
  closeTime: string
  createdAt: string
  updatedAt: string
}

export type Timecard = {
  id: string
  date: string
  shop: string
  staff: string
  readonly staffCode: string
  readonly staffName: string
  startTime: string
  endTime: null | string
  createdAt: string
}