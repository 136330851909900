import EventEmitter from 'eventemitter3'

export default class WebSocketListener extends EventEmitter {
  constructor(url, config) {
    super()
    this.url = url
    this.websocket = null
    this.closed = false
    this._pingIntervalId = null

    this._lastPingId = null
    this._lastPingSentAt = null
    this._lastPongReceivedAt = null
    this._messageId = 0

    this.config = Object.assign({}, WebSocketListener.defaults, config || {})
    this.reconnectionTimeout = this.confignitialReconnectionTimeout
    if (!this.config.WebSocket) this.config.WebSocket = WebSocket
    this.open()
  }

  get readyState() {
    if (this.websocket) {
      return this.websocket.readyState
    }
    return null
  }

  open() {
    this.clear()
    if (this.closed) return
    const conn = new this.config.WebSocket(this.url)
    this.websocket = conn
    conn.onopen = e => {
      this.reconnectionTimeout = this.config.initialReconnectionTimeout
      this.emit('open', e)
    }
    conn.onerror = e => {
      this.emit('error', e)
    }
    conn.onclose = e => {
      this._onclose(e)
    }
    conn.onmessage = e => {
      this.emit('message', e)
      try {
        const data = e.data
        // this.emit(`data:${data.type}`, this.config.transformData(data), e)
        // console.log('?data', data)
        if (data === 'pong') {
          this.emit('data:pong')
        } else {
          this.emit('data', data)
        }
      } catch (err) {
        console.error(err)
        this.emit('error', err)
      }
    }
    this.on('data:pong', () => {
      //if (this._lastPingId === id) {
      this._lastPongReceivedAt = new Date().getTime()
      // }
    })

    this._lastPongReceivedAt = new Date().getTime()

    this._pingIntervalId = setInterval(() => {
      if (!this.closed && this.readyState === 1) {
        if (
          this._lastPingSentAt - this._lastPongReceivedAt >
          this.config.pingInterval * 2
        ) {
          // pongがかえってこないので再接続
          console.log('ping timeout. reconnect...')
          this.open()
          return
        }
        const id = ++this._messageId
        this._lastPingSentAt = new Date().getTime()
        this._lastPingId = id
        this.websocket.send(JSON.stringify({ id, type: 'ping', payload: null }))
      }
    }, this.config.pingInterval)

    return this
  }

  close() {
    this.clear()
    this.closed = true
  }

  clear() {
    if (this._pingIntervalId) clearInterval(this._pingIntervalId)
    const conn = this.websocket
    if (!conn) return
    conn.close()
    conn.onopen = null
    conn.onerror = null
    conn.onclose = null
    conn.onmessage = null
  }

  _onclose(e) {
    console.log('_onclose', e.code, e)
    this.reconnectionTimeout *= 2
    if (this.reconnectionTimeout > this.config.maxReconnectionTimeout) {
      this.reconnectionTimeout = this.config.maxReconnectionTimeout
    }
    setTimeout(() => {
      // NOTE: 別の場所で再接続している場合はなにもしない
      if (this.readyState !== 1) {
        console.log('reopen websocket', this.url)
        this.open()
      }
    }, this.reconnectionTimeout)
  }

  destroy() {
    this.close()
    this.removeAllListeners()
  }
}

WebSocketListener.defaults = {
  initialReconnectionTimeout: 1000,
  maxReconnectionTimeout: 10000,
  transformData: data => data,
  pingInterval: 1000,
}
